<template>
<div class="container">
  
  <SpacerRow />
  
  <div class="row">
    <div class="rc-box-col-5"></div>
    <div class="rc-box-col-5 rc-text-centre rc-font-large rc-text-label">Sign In</div>
    <div class="rc-box-col-5"></div>
  </div>
  
  <ErrorRow :left="LEFT_PADDING" :right="RIGHT_PADDING" :error="error" />
  
  <KeyEmail
    :left="LEFT_PADDING" :right="RIGHT_PADDING" 
    title="Email" 
    placeholder="Email"
    :defaultValue="email"
    v-on:value="withEmail" />

  <KeyPassword 
    :left="LEFT_PADDING" :right="RIGHT_PADDING" 
    title="Password"
    placeholder="Password"
    :defaultValue="password"
    v-on:value="withPassword" />
  
  <KeyCheck
    :left="LEFT_PADDING" :right="RIGHT_PADDING" 
    title="rememberMe" 
    placeholder="Remember me"
    :disabled="isSignInDisabled"
    :defaultValue="remember"
    v-on:value="rememberMe" />
  
  <div class="row">
    <div class="rc-box-col-5"></div>
    <div class="rc-box-col-5 rc-text-centre rc-text-label">{{ status }}</div>
    <div class="rc-box-col-5"></div>
  </div>

  <div class="row">
    <div class="rc-box-col-5"></div>
    <div class="rc-box-col-5">
      <GreenButton v-on:click="signIn" :disabled="isSignInDisabled" label="Sign In" />
    </div>
    <div class="rc-box-col-5"></div>
  </div>

  <div class="row">
    <div class="rc-box-col-5"></div>
    <div class="rc-box-col-3">
      <router-link to="/recovery" onclick="w3_close()" class="w3-bar-item w3-button rc-font-small rc-border-round">Forget your password?</router-link>
    </div>
    <div class="rc-box-col-1">
    </div>
    <div class="rc-box-col-1 rc-align-left">
      <router-link to="/register" onclick="w3_close()" class="w3-bar-item w3-button rc-font-small rc-border-round">Sign Up</router-link>
    </div>
    <div class="rc-box-col-5"></div>
  </div>

</div>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mapGetters, mapActions } from 'vuex';

import GreenButton from '@/components/button/GreenButton.vue';
import KeyEmail from '@/components/input/KeyEmail.vue';
import KeyPassword from '@/components/input/KeyPassword.vue';
import KeyCheck from '@/components/input/KeyCheck.vue';

import ErrorRow from '@/components/row/ErrorRow.vue';
import SpacerRow from '@/components/row/SpacerRow.vue';

import Catelog from '@/domain/session/CanadianEnglish.js';

import EventUtils from '@/utils/EventUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import DateUtils from '@/utils/DateUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';

//import Company from '@/domain/model/company/Company.js';
import UserEvents from '@/domain/model/user/UserEvents.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';
import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';
//import RcDomain from '@/domain/RcDomain.js';
//import OnErrorAdapter from "./OnErrorAdapter.js";

export default {
  name: 'signin',
  components: {
    GreenButton,
    KeyEmail,
    KeyPassword,
    KeyCheck,
    ErrorRow,
    SpacerRow,
  },
  data() {
    return {
      email: '',
      password: '',
      status: '',
      signinDisabled: true,
      error: null,
      timer: null,
      remember: false,
      inProgress: false,
      LEFT_PADDING: 5,
      RIGHT_PADDING: 5,
      catelog: Catelog.KEYS,
    }
  },
  computed: {
    ...mapGetters([
        'auth_socket', 
        'auth_connected', 
        'auth_socket_status', 
        'auth_socket_message', 
        'auth_client',
        'user_roles',
        'signin_event',
        'domain',
        'inspections_found',
      ]),
    isSignInDisabled: function() {
      return this.signinDisabled;
    },
    isDebug: function() {
      if (this.auth_client) {
        return this.auth_client.debug;
      }
      return false;
    },
    
    console: () => console,
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.send();
      }
    },
    auth_socket_status() {
      if (!this.auth_connected && this.inProgress) {
        this.error = {
            messageId: "NoConnectionToApi",
            hintId: "It appears that ReadyChek.cloud's API is not reachable at the moment"
        }
        return;
      }
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      if (this.signin_event) {
        this.inProgress = false;
        var event = new RcResponseEvent(this.domain, this.signin_event);
        if (event.error()) {
          this.error = UserEvents.SignIn.Errors.find(event.error());
          this.status = null;
          this.signinDisabled = false;
        } else {
          this.status = 'Done';
          ConnectionUtils.createDomain(this, this.callback);
          
          var cookie = { 
            email: this.email, 
            password: this.password,
          };

          if (this.remember) {
            cookie['auto'] = true;
          }
          
          cookie['time'] = DateUtils.now();
          cookie['User'] = this.domain.session().user().id();
          cookie['Company'] = "";
          
          var cookieString = JSON.stringify(cookie);
          this.$cookie.set("readychek.ca", cookieString, 365);
          window.localStorage.readyChek = cookieString;
          
          this.AuthStorage_updateCompany(null);
          
          var employees = this.domain.employees().findByUserId(this.domain.session().user().id());
          
          if (employees.size() > 1) {
            this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.COMPANY.SELECT });
          } else {
            var session = this.domain.session();
            var employee = employees.first();
            var company = employee.company().find();
            session.with(company);
            session.with(employee);
            
            cookie['Company'] = company.id();
            cookieString = JSON.stringify(cookie);
            window.localStorage.readyChek = cookieString;
            this.$cookie.set("readychek.ca", cookieString, 365);
            
            var roles = session.employee().roles().find();
            if (roles.isAdministrator()) {
              ConnectionUtils.withAdminCompany(this);
              this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.ADMIN_HOME });
            } else {
              this.AuthStorage_updateCompany(company);
              this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.HOME });
            }
          }
        }
      }
      this.status = null;
    }
  },
  created: function() {
    var cookieString = this.$cookie.get("readychek.ca");
    if (cookieString) {
      var cookie = JSON.parse(cookieString);
      this.password = cookie.password;
      this.email = cookie.email;
      if (cookie.auto) {
        this.remember = true;
      }
    }    
  },
  mounted: function () {
    this.status = null;
    this.AuthStorage_updateCompany(null);
    var cookieString = this.$cookie.get("readychek.ca");
    if (cookieString) {
      var cookie = JSON.parse(cookieString);
      this.withEmail({ value: cookie.email });
      this.withPassword({ value: cookie.password });
      if (cookie.auto) {
        this.timer = setInterval(this.autoSignIn, 1000);
      }
    }
  },

  methods: {
    ...mapActions([
       'addListenerAction',
       'removeListenerAction',
       'updateDomain',
       'AuthStorage_updateCompany',
       "authClear"
     ]),
    callback: function(msg) {
      this.status = msg;
    },
    
    autoSignIn: function() {
      this.status = "Automatically signing into ReadyChek"
      if (!this.signinDisabled) {
        this.signIn();
      }
    },
    signIn: function () {
      clearInterval(this.timer)
      this.error = null;
      if (this.auth_connected) {
        this.send();
      } else {
        this.authClear();
        try {
          this.$connect();
        } catch (e) {
          this.error = {
              messageId: "NoConnection",
              hintId: "It appears that ReadyChek.cloud is not reachable at the moment"
          }
        }
      }
    },
    withEmail: function(email) {
      this.email = email.value;
    },
    withPassword: function(password) {
      this.password = password.value;
      this.check();
    },
    check: function() {
      if (this.password && this.password.length >= 8 && this.email.length >= 6) {
        this.signinDisabled = false;
      } else {
        this.signinDisabled = true;
      }
    },
    rememberMe: function() {
      this.remember = !this.remember;
    },
    send: function () {
      this.sendWithReCaptcha('');
    },
    sendForSignin: function() {
      this.grecaptcha.execute(ConstUtils.GRECAPTCHA.ID, {action: ConstUtils.GRECAPTCHA.PAGES.SIGNIN}).then(this.sendWithReCaptcha);
    },
    sendWithReCaptcha: function(token) {
      this.signinDisabled = true;
      this.inProgress = true;
      this.error = null;
      this.status = 'Connecting to ReadyChek.cloud...'
      const requestEvent = UserEvents.SignIn.Request(this.email, this.password);
      requestEvent[ConstUtils.GRECAPTCHA.NAME] = token;
      requestEvent['maker'] = window.navigator.userAgent;
      EventsDomain.initEvent(requestEvent, null, uuid.v1(), this.auth_client.deviceId, this.auth_client.version);
      EventUtils.Send.event(this.$socket, requestEvent);
    },
  }  
}
</script>